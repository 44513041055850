@property --border-angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
}

@keyframes bg-spin {
    to {
        --border-angle: 1turn;
    }
}

.box {
    color: white;
    padding: 2px;
    text-shadow: 0 1px 0 #000;

    --border-angle: 0turn;

    /* Updated background to complement the border */
    --main-bg: conic-gradient(
        from var(--border-angle),
        #511, /* Dark Red */
        #311 5%,
        #311 60%,
        #511 95%
    );

    /* Border animation with Green & Yellow */
    --gradient-border: conic-gradient(
        from var(--border-angle),
        transparent 25%,
        #32CD32, /* Lime Green */
        #FFD700 99%, /* Gold/Yellow */
        transparent
    );

    border: solid 5px transparent;

    background: 
        var(--main-bg) padding-box,
        var(--gradient-border) border-box, 
        var(--main-bg) border-box;

    background-position: center center;

    animation: bg-spin 1s linear infinite;
}






.dialog-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  @media (max-width: 768px) {
    .dialog-bg {
      background-image: url('../../assets/Artboard4.webp');
    }
  }
  
  @media (min-width: 769px) {
    .dialog-bg {
      background-image: url('../../assets/Artboard3.webp'); 
    }
  }
